body{
    
    margin-left: 20px;
}

.main-div{
    display:flex;
    flex-direction: row;
    gap:10vw;
   
    margin-top: 10vh;
}

.div-one{
    display:flex;
    flex-direction: column;
    gap:10vh;
    
}

.div-one-content-1{
    display:flex;
    flex-direction: column;
    gap:2vh;

}

.blogPost-text-1{
    font-size: 40px;
    font-weight: lighter;
}

.blogPost-text-2{
    font-size: 20px;
    font-weight: lighter;
}

.view-all{
    position: relative;
    bottom:0;
    left:0;
    margin-top: 10px;
    margin-right:10vw;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom utility class for scrolling animation */

@layer utilities{
    .paused{
        animation-play-state: paused;
    }
}

*{
    margin:0;
    padding:0;
    /* box-sizing:border-box; */
    
}

html,body{
    width:100%;
    margin:0;
    padding:0;
    /* overflow: hidden; */
    
    /* position:relative; */
}

/* #wrapper{
    max-width: 940px;
    margin:0 auto;
    padding:0 5%;
    clear:both;
    
} */

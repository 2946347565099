.auxo-ortus{
    display: flex;
    flex-direction: column;
    margin-top: 40vh;
}

.text-auxo-ortus{
    font-size:100px ;
    text-align: center;
    font-weight: lighter;
}
.img-auxo-ortus{
    height:100;
    width:100;
    align-self: center;

}

.text-auxo-ortus-1{
    font-size: 20px;
    font-weight: lighter;
    margin:20vw ;
    margin-top: 50px;
    text-align: justify;
}